import {
    Component,
    OnInit,
    Renderer2,
    ViewChild,
    ElementRef,
} from '@angular/core';
import { getNameInitials } from '@core/helpers/app.helper';
import { BaseHttpService } from '@core/services/base-http.service';
import { CryptoService } from '@core/services/crypto.service';
import { UserService } from '@modules/user-management/services/user.service';
import * as _ from 'underscore';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AliasUserService } from '@modules/alias-user/services/alias-user.service';
import { LoginService } from '@core/services/login.service';
import { authSettings } from '@config/oauth';
import { GlobalSearchService } from '@core/services/global-search.service';
import { Router, NavigationEnd } from '@angular/router';
import { DashboardService } from '@modules/dashboard/services/dashboard.service';
import { ToastService } from '@core/services/toast.service';
import { Output, EventEmitter, Input } from '@angular/core';
import { MaterialModule } from '@material/material.module';


@Component({
    selector: 'app-base',
    templateUrl: './base.component.html',
    styleUrls: ['./base.component.scss'],
})
export class BaseComponent implements OnInit {
    @ViewChild('drawerContainer') drawerContainer: ElementRef;
    @ViewChild('drawer') drawer: ElementRef;
    @ViewChild('aliasUserModal') aliasUserModald: ElementRef;
    @Output() dropDownvalue = new EventEmitter();
    viewportWidth: number;
    isDesktop: boolean;
    isMenuOpen = true;
    id_token: string;
    cv_country_id: any;
    selectedOption: any;
    selectedCountry: boolean = true;
    breakPoint = 300;
    links = [
        { url: '/hino/dashboard', text: 'Dashboard', icon: 'speed' },
        { url: '/hino/my-fleet', text: 'My Fleet', icon: 'commute' },
        { url: '/hino/telematics', text: 'Telematics', icon: 'memory' },
        { url: '/hino/ticf', text: 'TICF', icon: 'ticf' },
        // {
        //     url: '/hino/remote-diagnostics',
        //     text: 'Remote Diagnostics',
        //     icon: 'calendar_today',
        // },
        {
            url: '/hino/case-management',
            text: 'Case Management',
            icon: 'chat_bubble_outline',
        },
        {
            url: '/hino/user-management',
            text: 'User Management',
            icon: 'supervisor_account',
        },
        {
            url: '/hino/depot-management',
            text: 'Depot Management',
            icon: 'directions_bus',
        },
        { url: '/hino/geofencing', text: 'Geofencing', icon: 'explore' },
        { url: '/hino/dtc-error', text: 'PTO', icon: 'oil_can' },
    ];
    icons = {
        Dashboard: 'speed',
        'My Fleet': 'commute',
        'Case Management': 'chat_bubble_outline',
        'User Management': 'supervisor_account',
        'Depot Management': 'directions_bus',
        //'Remote Diagnostics': 'calendar_today',
        Telematics: 'memory',
        Geofencing: 'explore',
        dtc: 'oil_can',
        ticf: 'ticf'
    };
    options = [
        { label: 'USA ', value: 'USA' },
        { label: 'CA', value: 'CA' },
        { label: 'ALL', value: 'ALL' }
    ];
    initials: string;
    userMail = 'johndoe@incture.com';
    user: any = null;
    CVdata = {};
    userAccount;
    isUserSelected: boolean = false;
    userForSimulation;
    startSimulation = false;
    isCorporateUser;
    modalReferenceVariable;
    userList = [];
    globalSearchInput = "";
    currentRoute: string = 'dashboardUrl';
    currentRouteUrl: string = '/hino/dashboard';
    @ViewChild('aliasUserModal') aliasUserModalId: ElementRef;
    aliasPopupLoading: boolean = false;
    fleetList = [];
    selectedValue: any;

    constructor(
        public window: Window,
        private renderer: Renderer2,
        private http: BaseHttpService,
        private cryptoService: CryptoService,
        private userService: UserService,
        private router: Router,
        private modalService: NgbModal,
        private aliasUserService: AliasUserService,
        private loginService: LoginService,
        private globalSearch: GlobalSearchService,
        private dashboardService: DashboardService,
        private toast: ToastService
    ) {
        this.user = this.cryptoService.decryptData(
            localStorage.getItem('user')
        );
        this.userAccount = this.cryptoService.decryptData(
            localStorage.getItem('userAccount')
        );
        this.CVdata = this.cryptoService.decryptData(
            localStorage.getItem('roleId')
        );
        this.checkSimulationMode();
        this.setViewportWidth();
        this.setIsMobile();
        this.getPermissions();

        // current route
        this.router.events.subscribe(route => {
            if (route instanceof NavigationEnd) {
                switch (route.url) {
                    case '/hino/dashboard':
                        this.currentRoute = 'dashboardUrl';
                        this.currentRouteUrl = '/hino/dashboard';
                        break;
                    case '/hino/my-fleet':
                        this.currentRoute = 'fleetUrl'
                        this.currentRouteUrl = '/hino/my-fleet';
                        break;
                    case '/hino/case-management':
                        this.currentRoute = 'caseUrl'
                        this.currentRouteUrl = '/hino/case-management';
                        break;
                    case '/hino/user-management':
                        this.currentRoute = 'userUrl'
                        this.currentRouteUrl = '/hino/user-management';
                        break;
                    case '/hino/depot-management':
                        this.currentRoute = 'depotUrl'
                        this.currentRouteUrl = '/hino/depot-management';
                        break;
                    case '/hino/remote-diagnostics':
                        this.currentRoute = 'userUrl'
                        this.currentRouteUrl = '/hino/remote-diagnostics';
                        break;
                    case '/hino/geofence':
                        this.currentRoute = 'userUrl'
                        this.currentRouteUrl = '/hino/geofence';
                        break;
                    case '/hino/telematics':
                        this.currentRoute = 'telematicsUrl'
                        this.currentRouteUrl = '/hino/telematics';
                        break;
                    case '/hino/ticf':
                        this.currentRoute = 'ticfUrl'
                        this.currentRouteUrl = '/hino/ticf';
                        break;
                }
            }
        });
    }

    ngOnInit(): void {
        // this.selectedCountry = this.user?.country?.countryId == 1 ? false : true;
        this.initials = getNameInitials(`${this.user.firstname} ${this.user.lastname}`);

        let country_id = localStorage.getItem('cv_country_id')
        if (country_id == '2') {
            this.selectedOption = 'USA';
        }
        else if (country_id == '3') {
            this.selectedOption = 'CA';
        }
        else {
            this.selectedOption  = 'ALL';
        }
        // to hide the simulate option for non-corporate users
        if (this.userAccount?.isCorporate === true) {
            this.isCorporateUser = true;
        } else {
            this.isCorporateUser = false;
        }
    }
    emailData = {
        subject: '',
        startDate: new Date(),
        endDate: new Date(),
        emails: [] as string[], // Array to hold list of recipients
        emailBody: 'Hi,\nWe would like to inform you that the application is currently undergoing performance maintenance, which may result in minor disruptions to our services. We sincerely apologize for any inconvenience this may cause. Should you require further information or assistance, please feel free to reach out to us at ultimatesupport@hino.com.\nThank you for your understanding.',
        attachments: [],
      };
      recipientInput: string = '';
      errorMessage: string = '';
      defaultEmailBody = 'Hi,\nWe would like to inform you that the application is currently undergoing performance maintenance, which may result in minor disruptions to our services. We sincerely apologize for any inconvenience this may cause. Should you require further information or assistance, please feel free to reach out to us at ultimatesupport@hino.com.\nThank you for your understanding.';

    
      onSubmit(form: any) {
        const formData = new FormData();
        formData.append('subject', this.emailData.subject);
        formData.append('startDate', JSON.stringify(this.emailData.startDate));
        formData.append('endDate', JSON.stringify(this.emailData.endDate));
        
        // Send recipients directly as an array
        formData.append('emails', JSON.stringify(this.emailData.emails));
    
        formData.append('emailBody', this.emailData.emailBody);
    
        // if (this.emailData.attachments.length) {
        //   for (const file of this.emailData.attachments) {
        //     formData.append('attachments', file);
        //   }
        // }
        
        if (!form.valid) {
            this.errorMessage = 'Please fill in all mandatory fields.';
            return false; 
          }
        for (const recipient of this.emailData.emails) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/; // Simple email regex
        if (!emailRegex.test(recipient)) {
            this.errorMessage = 'Invalid email address: ' + recipient;
            return false;
        }
        }
       
        console.log(this.emailData);
        this.http.post('maintenance_notification', this.emailData).subscribe({
          next: (response) => {
            alert('Email sent successfully!');
          },
          error: (error) => {
            alert('Failed to send email. Please try again.');
          },
        });
        form.resetForm();
        this.emailData.emailBody = this.defaultEmailBody; 
        this.recipientInput = '';
        return true;
      }

      onCancel(emailForm: any, modal: any): void {
        emailForm.resetForm();
        this.recipientInput = ''; 
        this.emailData.emailBody = this.defaultEmailBody; 
        modal.close(); 
      }
      
    
    //   onFileChange(event: any) {
    //     if (event.target.files) {
    //       this.emailData.attachments = Array.from(event.target.files);
    //     }
    //   }
    
      // Update recipients to handle the input as an array of email strings
      onRecipientsChange(value: string) {
        this.recipientInput = value;

        // Split the string by commas, trim spaces and filter out empty strings
        this.emailData.emails = value.split(',').map(email => email.trim()).filter(email => email.length > 0);
      }

    setViewportWidth = () => (this.viewportWidth = this.window.screen.width);

    setIsMobile = () => {
        if (this.viewportWidth > this.breakPoint) this.isDesktop = true;
        else this.isDesktop = false;
    };

    // reset viewport width on window resize
    resizeEvent(event) {
        this.setViewportWidth();
        if (
            this?.drawerContainer?.nativeElement &&
            this?.drawer?.nativeElement
        ) {
            if (this.viewportWidth > this.breakPoint) {
                this.renderer.removeClass(
                    this.drawerContainer.nativeElement,
                    'menu-closed'
                );
                this.renderer.removeClass(
                    this.drawer.nativeElement,
                    'menu-closed'
                );
            }
        }
    }

    /** search events */
    searchOutput(searchQuery: string) { }


    /** fetch events */
    fetchOutput(fetchQuery: string) { }

    userProfilePage(section) {
        if (this.router.url.includes('/user-management')) {
            window.history.replaceState(
                {},
                '',
                `/hino/user-management?page=${section}`
            );
            this.currentRoute = 'userUrl';
            this.userService.loggedInUserAction.next(`${section}View`);
        } else {
            this.currentRoute = 'userUrl';
            this.router.navigate([`/hino/user-management`], {
                queryParams: { page: section },
            });
        }
    }
    logout() {
        localStorage.clear();
        document.cookie = "sessionCookie=; max-age=0; path=/;";
        window.location.href = authSettings['b2c_auth'];
    }

    getPermissions() {
        const user = this.cryptoService.decryptData(localStorage.getItem('userType'));
        const permissions = this.cryptoService.decryptData(localStorage.getItem('permissions'));
        const simulation = this.cryptoService.decryptData(localStorage.getItem('simulation'));
        this.links = permissions ? permissions.permissions : [];
        this.links.map((link: any) => {
            if (link.url == '/hino/telematics') {
                link.url = '/hino/telematics'  // replace '/hino/telematics' instead of 'https://www.google.com/'
                link.urlType = {
                    url_type_code: "EXTERNAL_NG",
                    url_type_id: 12101,
                    url_type_text: "EXternal Angular"
                };
            }
        });
         if (simulation) {
            let remoteDiagIndex = this.links.findIndex((link) => {
                return link.url == "/hino/remote-diagnostics"
            });
            if (remoteDiagIndex > -1) this.links.splice(remoteDiagIndex, 1);
        }
    }

    // Function to open modals = referenceVariable refers to the id of the modal which should be opened
    open(loaderModal) {
        this.modalReferenceVariable = this.modalService.open(loaderModal, {
            size: 'md',
            backdrop: 'static', keyboard: false
        });
        this.modalReferenceVariable.result.then(
            (result) => { },
            (reason) => {
            }
        );
        if (!this.isCorporateUser) {
            this.isUserSelected = false;
            this.fleetList = [];
            this.aliasPopupLoading = true;
            this.aliasUserService.userFleetFetch(this.user.emailAddress, this.user.acccount.fleetCode).then((response: any) => {
                this.fleetList = response.data;
                this.aliasPopupLoading = false;
                if (this.fleetList.length === 0) {
                    this.isUserSelected = true;
                }

            });
        }
    }
    close(loaderModal) {
        this.modalReferenceVariable.close();
    }

    doneAliasUser() {
        this.modalReferenceVariable.close();
        this.toast.info('Login Process Initiated..');
        this.loginService.backupToken();
        this.loginService
            .createLoginPayload(null, this.userForSimulation)
            .then((payload) => {
                // this.loginService.restoreToken();
                let loginApiUrl = "";
                // if (this.userAccount.isCorporate || this.cryptoService.decryptData(localStorage.getItem('simulation'))){
                //     loginApiUrl = `users/alias-login?fleetCode=${this.userForSimulation.account.fleet_code}&`;
                //     payload["fleetCode"] = this.userForSimulation.account.fleet_code;
                //  }else{
                //     loginApiUrl = `users/login?fleetCode=${this.userForSimulation.fleet_code}&`;
                //     payload["fleetCode"] = this.userForSimulation.fleet_code;
                //  }
                if (this.userAccount.isCorporate) {
                    loginApiUrl = `users/alias-login?fleetCode=${this.userForSimulation.account.fleet_code}&`;
                    payload["fleetCode"] = this.userForSimulation.account.fleet_code;
                    payload["countryId"] = this.user.country.countryId;
                } else {
                    loginApiUrl = `users/switch-login?fleetCode=${this.userForSimulation.fleet_code}&`;
                    payload["fleetCode"] = this.userForSimulation.fleet_code;
                    payload["countryId"] = this.user.country.countryId;
                }
                this.loginService
                    .login(payload, loginApiUrl)
                    .then((loginResponse: any) => {
                        this.loginService
                            .saveLoginData('simulation', loginResponse)
                            .then((loginSuccess) => {
                                this.startSimulation = true;
                                this.setHeaders();
                                this.getPermissions();
                                this.currentRouteUrl == '/hino/dashboard' ? location.reload() : this.router.navigate(['/hino/dashboard']);
                                this.toast.success('Simulation mode on');
                                this.dashboardService.updateAction.next(true);
                            });
                    });
            });

        // use the below lines of code after simulation is complete to reset the values
        // this.userForSimulation = null;
        // this.startSimulation = false;
    }

    // dropdown for cv admin
    onSelectionChange(selectedValue: any) {
        
        //localStorage.getItem('cv_country_id')
        if (selectedValue == 'USA') {
            this.cv_country_id = 2;
        }
        else if (selectedValue == 'CA') {
            this.cv_country_id = 3;
        }
        else {
            this.cv_country_id = null;
        }
        localStorage.setItem('cv_country_id', this.cv_country_id);
        //this.selectedOption = selectedValue;
        //console.log(this.selectedOption);
        // Reload the page immediately after selection
        this.window.location.reload();
        
    }


    selectedUserForSimulation(user) {
        if (this.userAccount.isCorporate) {
            this.userForSimulation = user;
        } else {
            user["email_address"] = this.user.emailAddress;
            this.userForSimulation = user;
        }
    }
    closeSimulation() {
        this.loginService.restoreSimulationData();
        this.setHeaders();
        this.getPermissions();
        this.currentRouteUrl == '/hino/dashboard' ? location.reload() : this.router.navigate(['/hino/dashboard']);
        this.dashboardService.updateAction.next(true);
        this.toast.success('Simulation mode off');
        this.userForSimulation = null;
        this.startSimulation = false;
    }

    setHeaders() {
        this.user = this.cryptoService.decryptData(
            localStorage.getItem('user')
        );
        this.userAccount = this.cryptoService.decryptData(
            localStorage.getItem('userAccount')
        );
        this.initials = getNameInitials(`${this.user.firstname} ${this.user.lastname}`);
        if (this.userAccount.isCorporate === true) {
            this.isCorporateUser = true;
        } else {
            this.isCorporateUser = false;
        }
    }

    checkSimulationMode() {
        let simulationMode = localStorage.getItem('simulation');
        if (simulationMode) {
            this.user = this.cryptoService.decryptData(
                localStorage.getItem('user')
            );
            this.userForSimulation = this.user;
            this.startSimulation = true;
        }
    }

    openExternalUrl(instance) {
        window.open(instance.url, "", "width=1000,height=500,top=300,left=600");
    }

    /** search */
    startGobalSearch(searchQuery: string) {
        this.globalSearch.myFleetsearch.next(searchQuery);
    }
    /** fetch*/
    // startGobalFetch(fetchQuery: string) {
    //     this.globalSearch.myFleetsearch.next(fetchQuery);
    // }

    openHelp(helperModal) {
        this.modalReferenceVariable = this.modalService.open(helperModal, {
            size: 'sm',
            backdrop: 'static', keyboard: false
        });
        this.modalReferenceVariable.result.then(
            (result) => { },
            (reason) => {
            }
        );
    }

}